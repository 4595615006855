import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
	API_URL_PROAM as API_URL,
	getValFromLS,
	scroll,
	createMarkup,
} from "../../utils";

import Alert from "../../shared/Alert";
import Notice from "../../shared/Notice";

import EventTable from "../EventTable";
import Nav from "../Nav";

import Tiles from "./Tiles";

function DashHome() {
	const [user, setUser] = useState({});
	const [wed, setWed] = useState({});
	const [thu, setThu] = useState({});
	const [html, setHtml] = useState({});
	const [open, setOpen] = useState({
		wed: false,
		thu: false,
	});
	const [notice, setNotice] = useState({});
	const [notice2, setNotice2] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	const init = async () => {
		const auth = getValFromLS("proam-dash", true)
			? JSON.parse(getValFromLS("proam-dash", true))
			: {};

		const url = new URL(`${API_URL}/`);
		const params = {
			f: `dashboardInit`,
			id: auth.id ? auth.id : "",
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setUser({ ...auth });
				setWed({ ...json.wed });
				setThu({ ...json.thu });
				setHtml({ right: json.right });

				if (location?.state?.data) {
					setNotice({
						type: location.state.data.type,
						text: location.state.data.text,
					});
				}

				if (json.contactUpdateReq) {
					setNotice2({
						type: "notice",
						text: "Please update your contact information.",
					});
				} else if (json.participantsReq) {
					setNotice2({
						type: "notice",
						text: "Please be sure add yourself and/or your participants before registration closes Friday, March 7th.",
					});
				}

				setAlert({});
				scroll();
			} else {
				navigate("/dashboard/signin", {
					state: {
						data: { type: "error", text: json.text },
					},
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred, invalid or missing access credentials.",
			});
		}
	};

	const handleToggle = (id) => {
		const newOpen = !open[id];
		setOpen({ ...open, [id]: newOpen });
	};

	return (
		<>
			<h3 className="subheading">Team Management Dashboard</h3>

			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="left-right-grid">
					<div id="left-column">
						{notice.type && <Notice data={notice} />}
						{notice2.type && <Notice data={notice2} />}

						<p className="p1">
							We look forward to welcoming you and your guests to Mission Hills
							Country Club Tournament Course to play with the PGA TOUR Champions
							players.
						</p>

						<p className="p1" style={{ marginTop: "15px" }}>
							To begin inviting or registering your participants, please select
							the “Add/Invite Particpant” button below. Please note:{" "}
							<b>Registration will close Friday, March 7</b> - All registration
							information must be completed by this date.
						</p>

						<Tiles />

						{wed.data.length > 0 && (
							<EventTable
								details={wed}
								id="wed"
								open={open.wed}
								toggle={handleToggle}
							/>
						)}
						{thu.data.length > 0 && (
							<EventTable
								details={thu}
								id="thu"
								open={open.thu}
								toggle={handleToggle}
							/>
						)}
					</div>

					<div id="right-column">
						<Nav on={1} user={user} />

						<div dangerouslySetInnerHTML={createMarkup(html.right)} />
					</div>
				</div>
			)}
		</>
	);
}

export default DashHome;
