import "./styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/pro-light-svg-icons";

function Player({ data }) {
	return (
		<div className="player-container">
			<div className="player-image">
				<div className="image">
					<img src={data.img} alt={data.name} title={data.name} />
				</div>
				<div className="player-flag">
					<img
						src={`https://qnmcdn.qnm.workers.dev/tourneyweb.com/?path=%2Fassets%2Fflag-icons%2F${data.country}.png%3F607&ma=86400`}
						alt={`Flag of ${data.country}`}
						title={data.country}
					/>
				</div>
				{data.champ && (
					<div className="player-champion" title="The Galleri Classic Champion">
						<FontAwesomeIcon icon={faTrophy} />
					</div>
				)}
			</div>
			<div className="player-name-container">
				<div>{data.name}</div>
				<div>{data.locale}</div>
			</div>
		</div>
	);
}

export default Player;
