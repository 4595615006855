import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL, getValFromLS, scroll } from "../../utils";

import Alert from "../../shared/Alert";
import Notice from "../../shared/Notice";

import Nav from "../Nav";

import NameTable from "./NameTable";
import StatusesTable from "./StatusesTable";
import ResendForm from "./ResendForm";
import DetailsTable from "./DetailsTable";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare } from "@fortawesome/pro-light-svg-icons";

function Guest() {
	const [user, setUser] = useState({});
	const [details, setDetails] = useState({});
	const [notice, setNotice] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);
	const { str } = useParams();

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	const init = async () => {
		scroll();
		const auth = getValFromLS("proam-dash", true)
			? JSON.parse(getValFromLS("proam-dash", true))
			: {};

		const url = new URL(`${API_URL}/`);
		const params = {
			f: `dashboardGuestInit`,
			q: str ? str : "",
			q2: auth.id ? auth.id : "",
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (!auth.id) {
				setAlert({
					type: "error",
					text: "An error has occurred.",
				});
			} else if (json && (json.resp === 1 || json.resp === 2)) {
				setUser({ ...auth });
				setDetails({ ...json });

				if (json.invite.wed.status === 2 || json.invite.thu.status === 2) {
					setNotice({
						type: "notice",
						text: "Your participant has pending invitation(s), see below to resend their invite.",
					});
				} else if (json.needsAttention) {
					setNotice({
						type: "notice",
						text: "Your participant details need completion, please click below to update.",
					});
				}

				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleRemove = async (e, id, event) => {
		e.preventDefault();

		const url = new URL(`${API_URL}/?f=dashboardGuestRemove`);
		let formData = new FormData();
		formData.append("q", id);
		formData.append("event", event);
		formData.append("auth", getValFromLS("proam-dash", true));
		formData.append("x", uuidv4());

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setDetails({ ...json });
			} else {
			}
		} catch (error) {}
	};

	return (
		<>
			<h3 className="subheading">Participant Details / Invitation Status</h3>

			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="left-right-grid">
					<div id="left-column">
						{notice.type && <Notice data={notice} />}

						<p className="p1">
							Use this page to view your participant’s registration and manage
							invitation statuses.
						</p>

						<NameTable
							name={details.name}
							email={details.email}
							phone={details.phone}
						/>

						<StatusesTable
							id={details.id}
							wed={details.invite.wed}
							thu={details.invite.thu}
							handleRemove={handleRemove}
						/>

						{(details.invite.wed.status === 2 ||
							details.invite.thu.status === 2) && (
							<ResendForm email={details.email} id={details.id} />
						)}

						{details.invite.wed.status !== 2 &&
							details.invite.thu.status !== 2 && (
								<>
									<DetailsTable values={details.values} />

									<div className="link-container">
										<Link to={`/dashboard/guest/${details.id}/edit`}>
											<FontAwesomeIcon icon={faPencilSquare} size="2x" />
											<span>Edit Participant Details</span>
										</Link>
									</div>
								</>
							)}
					</div>

					<div id="right-column">
						<Nav on={0} user={user} />
					</div>
				</div>
			)}
		</>
	);
}

export default Guest;
