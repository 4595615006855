import { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL } from "../../utils";

import Alert from "../../shared/Alert";
import Notice from "../../shared/Notice";

import GuestDetailsForm from "../../shared/GuestDetailsForm";

import ResponseForm from "./ResponseForm";
import WedDetails from "./WedDetails";
import ThuDetails from "./ThuDetails";

import "./styles.css";

function Guest() {
	const [responseSubmitted, setResponseSubmitted] = useState(false);
	const [details, setDetails] = useState({});
	const [notice, setNotice] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);
	const { str } = useParams();
	const location = useLocation();

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	useEffect(() => {
		if (responseSubmitted) {
			setAlert({
				type: "working",
				text: "",
			});
			init();
		}
	}, [responseSubmitted]);

	const init = async () => {
		const url = new URL(`${API_URL}/`);
		const params = {
			f: `guestInit`,
			id: str ? str : "",
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && (json.resp === 1 || json.resp === 2 || json.resp === 3)) {
				setDetails({ ...json });

				if (location?.state?.data) {
					setNotice({
						type: location.state.data.type,
						text: location.state.data.text,
					});
				} else if (
					(json.resp === 2 || json.resp === 3) &&
					(json.invite.wed.status !== 3 || json.invite.thu.status !== 3) &&
					!json?.address
				) {
					setNotice({
						type: "notice",
						text: "Your registration details need to be completed, please use the form below.",
					});
				}

				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<h3 className="subheading">Participant Details</h3>

			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="left-right-grid">
					<div id="left-column">
						{notice.type && <Notice data={notice} />}

						{details.resp === 2 ? (
							<>
								<p className="p1">
									Use the form below to complete your participant registration
									details.
								</p>

								<GuestDetailsForm details={details} />
							</>
						) : (
							<ResponseForm
								id={details.id}
								registrationName={details.registrationName}
								fname={details.fname}
								lname={details.lname}
								events={details.events}
								setResponseSubmitted={setResponseSubmitted}
							/>
						)}
					</div>

					<div id="right-column">
						<div className="right-details-container">
							<h4>Play Inside the Ropes</h4>

							<p>
								The Galleri Classic Pro-Am provides an unmatched opportunity for
								golf enthusiasts to go inside-the-ropes and test their skills at
								the historic Mission Hills Country Club.
							</p>

							{details.invite.wed.status !== 3 && <WedDetails />}
							{details.invite.thu.status !== 3 && <ThuDetails />}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Guest;
