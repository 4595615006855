import Radios from "../Radios";
import Input from "../Input";

function PairingsPartyFields({ attending, guest, handleUpdate, errors }) {
	return (
		<>
			<div>
				<Radios
					label="Will you be attending the Tuesday, March 25th Pairings Party?"
					req={true}
					data={[
						{
							name: "attending",
							value: "yes",
							label: "Yes, by myself",
						},
						{
							name: "attending",
							value: "guest",
							label: "Yes, with a guest",
						},
						{
							name: "attending",
							value: "not",
							label: "I will not be attending",
						},
					]}
					value={attending}
					click={handleUpdate}
					border={false}
				/>
			</div>

			{attending === "guest" && (
				<div className="form-row-2">
					<Input
						type="text"
						label="Guest’s Name"
						req={true}
						name="guest"
						value={guest}
						errors={errors}
						autocomplete="off"
					/>

					<div className="empty" />
				</div>
			)}
		</>
	);
}

export default PairingsPartyFields;
